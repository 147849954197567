<template>
  <el-select
    clearable
    :value="value"
    @input="$emit('input', $event)"
    size="mini"
  >
    <el-option
      v-for="item in $locale.logic_editor.command.create_report.operation_types"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'OperationType',
  props: ['value']
}
</script>

<style scoped>

</style>
